$(document).on('turbolinks:load', function(){
  $("#stype a.add_fields").
    data("association-insertion-position", 'before').
    data("association-insertion-node", 'this');

  $('#stype').bind('cocoon:after-insert',
     function() {
       $("#stype_from_list").hide();
       $("#stype a.add_fields").hide();
     });
  $('#stype').bind("cocoon:after-remove",
     function() {
       $("#stype_from_list").show();
       $("#stype a.add_fields").show();
     });
});


$(document).on('turbolinks:load', function(){
  function check_to_hide_or_show_add_link() {
    if ($('#stype .nested-fields:visible').length == 1) {
       $('#stype .links a').hide();
     } else {
       $('#stype .links a').show();
     }
   }

   $('#stype').on('cocoon:after-insert', function() {
     check_to_hide_or_show_add_link();
   });

   $('#stype').on('cocoon:after-remove', function() {
     check_to_hide_or_show_add_link();
   });
   check_to_hide_or_show_add_link();
});
