// Function turns given time to unix to get lastseen
export function formatLastSeen(unixtimestamp) {
  const now = new Date();
  const diff = now - unixtimestamp;

  // Convert milliseconds to seconds, minutes, hours, or days as appropriate
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  if (days > 0) {
    return days + " days ago";
  } else if (hours > 0) {
    return hours + " hours ago";
  } else if (minutes > 0) {
    return minutes + " minutes ago";
  } else {
    return seconds + " seconds ago";
  }
}
// End: Function turns given time to unix to get lastseen


