$(document).on("turbolinks:load", function () {
  //console.log("Is turbolinks loading!")

  try {
    const firstDate = gon.wind_graphs[0].received_at.slice(0, 10);
    const lastDate = gon.wind_graphs[
      gon.wind_graphs.length - 1
    ].received_at.slice(0, 10);

    const data = [];
    const readingData = [];
    const readingAmount = 60


    window.addEventListener("resize", function() {
      if (window.matchMedia("(min-width: 450px)").matches) {
        const readingAmount = 60
      } else {
        const readingAmount = 10
      }
    })


    if (gon.wind_graphs.length > readingAmount + 1) {
      for (let i = 0; i < readingAmount + 1; i++) {
        const windSpeed = gon.wind_graphs[i].wind_speed;
        const windDirection = gon.wind_graphs[i].wind_direction;
        data.push([parseFloat(windSpeed), parseFloat(windDirection)]);
      }

    } else {
      for (let i = 0; i < gon.wind_graphs.length - 1; i++) {
        const windSpeed = gon.wind_graphs[i].wind_speed;
        const windDirection = gon.wind_graphs[i].wind_direction;
        data.push([parseFloat(windSpeed), parseFloat(windDirection)]);
      }
    }


    for (let i = 0; i < gon.wind_graphs.length - 1; i++) {
      const readingDate = gon.wind_graphs[i].received_at;
      readingData.push(readingDate);
    }

    console.log(readingData);

    //www.highcharts.com/forum/viewtopic.php?p=172970#p172970

    Highcharts.chart("container", {
      title: {
        text: " ",
      },
      credits: {
        enabled: false
    },

      subtitle: {
        align: "left",
      },

      xAxis: {
        type: "datetime",
        offset: 40,
      },

      yAxis: {
        title: {
          text: "Wind speed (m/s)",
        },
      },

      plotOptions: {
        series: {
          pointStart: Date.UTC(2023, 3, 14),
          pointInterval: 3600000,
          relativeXValue: true,
        },
      },

      series: [
        {
          type: "windbarb",
          data: data,
          name: "Wind",
          color: Highcharts.getOptions().colors[1],
          showInLegend: false,
          tooltip: {
            valueSuffix: " m/s",
          },
        },
        {
          type: "area",
          keys: ["y"], // wind direction is not used here
          data: data,
          color: Highcharts.getOptions().colors[0],
          fillColor: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, Highcharts.getOptions().colors[0]],
              [
                1,
                Highcharts.color(Highcharts.getOptions().colors[0])
                  .setOpacity(0.25)
                  .get(),
              ],
            ],
          },
          name: "Wind speed",
          tooltip: {
            valueSuffix: " m/s",
          },
          states: {
            inactive: {
              opacity: 1,
            },
          },
        },
      ],
    });
  } catch (error) {
    // handle error
    console.log(error);
    // alert("error called");
  }
});

