// TODO:
// remove popup when clicking on replay history

import { formatLastSeen } from "./functions.js";

$(document).on("turbolinks:load", function () {
  //console.log("Is turbolinks loading!")

  try {
    function mapLayer() {
      // Create the map layer & style
      const mapBoxToken =
        "pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";

      mapboxgl.accessToken = mapBoxToken;
      map = new mapboxgl.Map({
        container: "test-map", // container ID
        style: mapStyle, // style URL
        center: [
          gon.device_locations[0].longitude,
          gon.device_locations[0].latitude + 0.001,
        ], // starting position [lng, lat]
        attribution: "Nvirosense",
        zoom: 16, // starting zoom
        // pitch: 70,
        cooperativeGestures: true,
        attributionControl: false,
      });

      map.on("load", () => {
        map.addSource("dem", {
          type: "raster-dem",
          url: "mapbox://mapbox.mapbox-terrain-dem-v1",
        });
        map.addLayer(
          {
            id: "hillshading",
            source: "dem",
            type: "hillshade",
          },
          // Insert below land-structure-polygon layer,
          // where hillshading sits in the Mapbox Streets style.
          "land-structure-polygon"
        );
      });

      //End: Create the map layer & style
    }

    function modelBuildings() {
      map.on("style.load", () => {
        // Insert the layer beneath any symbol layer.
        const layers = map.getStyle().layers;
        const labelLayerId = layers.find(
          (layer) => layer.type === "symbol" && layer.layout["text-field"]
        ).id;

        // The 'building' layer in the Mapbox Streets
        // vector tileset contains building height data
        // from OpenStreetMap.
        map.addLayer(
          {
            id: "add-3d-buildings",
            source: "composite",
            "source-layer": "building",
            filter: ["==", "extrude", "true"],
            type: "fill-extrusion",
            minzoom: 15,
            paint: {
              "fill-extrusion-color": "#aaa",

              // Use an 'interpolate' expression to
              // add a smooth transition effect to
              // the buildings as the user zooms in.
              "fill-extrusion-height": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "height"],
              ],
              "fill-extrusion-base": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "min_height"],
              ],
              "fill-extrusion-opacity": 0.6,
            },
          },
          labelLayerId
        );
      });
    }

    function navControl() {
      // Adds zoom and navigation controls
      map.addControl(new mapboxgl.NavigationControl());
    }

    function latestMarkerPopup() {
      const longitude = gon.device_locations[0].longitude;
      const latitude = gon.device_locations[0].latitude;
      // Adds all the lats and lons from gon.jbuilder to the map, placing a marker and popup for each.

      const deviceName = gon.device_locations[0].device_name;
      const deviceType = gon.device_locations[0].type;

      let fullCoords = latitude + "°<br>" + longitude + "°";

      let dateString = gon.device_locations[0].received_at;

      let date = new Date(dateString);
      let unixTime = date.getTime();

      const formatDate = gon.device_locations[0].received_at.slice(0, 10);
      const formatTime = gon.device_locations[0].received_at.slice(11, 19);

      let elapsedTime = formatLastSeen(unixTime);

      // API to GET adress --> "https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw"
      const mapboxApi =
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        longitude +
        "," +
        latitude +
        ".json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
      fetch(mapboxApi)
        .then((response) => response.json())
        .then((data) => {
          // Extract the place_name property and store it in a variable
          const placeName = data.features[0].place_name;

          // Popup
          let popup = new AnimatedPopup({
            offset: 25,
            openingAnimation: {
              duration: 600,
              easing: "easeOutElastic",
              transform: "scale",
            },
            closingAnimation: {
              duration: 200,
              easing: "easeInBack",
              transform: "scale",
            },
          }).setHTML(`
          <h1 class='popup-head'><strong>${deviceType} : ${deviceName}</strong></h1>
          <table class="popup-nvirosense" style="table-layout: fixed; width: 199px">
          <colgroup>
          <col style="width: 90px">
          <col style="width: 80px">
          </colgroup>
          <thead>
            <tr>
              <th class="popup-nvirosense-0a7q"><span style="font-weight:400;font-style:normal">Address:</span></th>
              <th class="popup-nvirosense-73oq">${placeName}</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal">Time:</span></td>
              <td class="popup-nvirosense-73oq">${formatDate}<br>${formatTime}</td>
            </tr>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal">Last seen:</span></td>
              <td class="popup-nvirosense-73oq">${elapsedTime}</td>
            </tr>
            <tr>
              <td class="popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal">Position:</span></td>
              <td class="popup-nvirosense-73oq">${fullCoords}</td>
            </tr>
          </tbody>
          </table>
          `);
          // End: Popup

          // Marker
          const marker = new mapboxgl.Marker()
            .setLngLat([longitude, latitude])
            .setPopup(popup)
            .addTo(map);
          // End: Marker

          popup.addTo(map).setLngLat([longitude, latitude]).addTo(map);

          console.log(placeName);
        })
        .catch((error) => {
          // Handle any errors that occur during the fetch request
          // console.error(error);
        });
      // End: API to GET adress
    }

    function flyToLocation() {
      map.flyTo({
        center: [
          gon.device_locations[0].longitude,
          gon.device_locations[0].latitude,
        ],
        zoom: 17,
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      });
    }

    function replayHistory() {
      const target = [];
      const camera = [];

      for (let i = 0; i < gon.device_locations.length - 1; i++) {
        target.push([
          gon.device_locations[i].longitude,
          gon.device_locations[i].latitude,
        ]);
        camera.push([
          gon.device_locations[i].longitude + 0.001,
          gon.device_locations[i].latitude,
        ]);
      }

      mapboxgl.accessToken =
        "pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
      const map = new mapboxgl.Map({
        container: "test-map",
        zoom: 18,
        center: [
          gon.device_locations[0].longitude,
          gon.device_locations[0].latitude,
        ],
        pitch: 75,
        bearing: 0,
        // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
        style: mapStyle,
        interactive: false,
        attributionControl: false,
      });

      // `routes` comes from https://docs.mapbox.com/mapbox-gl-js/assets/routes.js,
      // which has properties that are in the shape of an array of arrays that correspond
      //  to the `coordinates` property of a GeoJSON linestring, for example:
      // [
      //   [6.56158, 46.05989],
      //   [6.56913, 46.05679],
      //   ...
      // ]
      // this is the path the camera will look at
      const targetRoute = target;
      // this is the path the camera will move along
      const cameraRoute = camera;

      // add terrain, sky, and line layers once the style has loaded
      map.on("style.load", () => {
        map.addSource("mapbox-dem", {
          type: "raster-dem",
          url: "mapbox://mapbox.mapbox-terrain-dem-v1",
          tileSize: 512,
          maxzoom: 12,
        });
        map.setTerrain({ source: "mapbox-dem", exaggeration: 1.5 });
        map.addSource("trace", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: targetRoute,
            },
          },
        });
        map.addLayer({
          type: "line",
          source: "trace",
          id: "line",
          paint: {
            "line-color": "black",
            "line-width": 2,
          },
          layout: {
            "line-cap": "round",
            "line-join": "round",
          },
        });
      });

      function modelBuildings() {
        map.on("style.load", () => {
          // Insert the layer beneath any symbol layer.
          const layers = map.getStyle().layers;
          const labelLayerId = layers.find(
            (layer) => layer.type === "symbol" && layer.layout["text-field"]
          ).id;

          // The 'building' layer in the Mapbox Streets
          // vector tileset contains building height data
          // from OpenStreetMap.
          map.addLayer(
            {
              id: "add-3d-buildings",
              source: "composite",
              "source-layer": "building",
              filter: ["==", "extrude", "true"],
              type: "fill-extrusion",
              minzoom: 15,
              paint: {
                "fill-extrusion-color": "#aaa",

                // Use an 'interpolate' expression to
                // add a smooth transition effect to
                // the buildings as the user zooms in.
                "fill-extrusion-height": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  15,
                  0,
                  15.05,
                  ["get", "height"],
                ],
                "fill-extrusion-base": [
                  "interpolate",
                  ["linear"],
                  ["zoom"],
                  15,
                  0,
                  15.05,
                  ["get", "min_height"],
                ],
                "fill-extrusion-opacity": 0.6,
              },
            },
            labelLayerId
          );
        });
      }
      modelBuildings();

      // wait for the terrain and sky to load before starting animation
      map.on("load", () => {
        let animationDuration = 10000;
        const cameraAltitude = 2600;
        // get the overall distance of each route so we can interpolate along them
        const routeDistance = turf.lineDistance(turf.lineString(targetRoute));
        const cameraRouteDistance = turf.lineDistance(
          turf.lineString(cameraRoute)
        );

        let start;

        document
          .getElementById("speed-x1")
          .addEventListener("click", function () {
            animationDuration = 10000;
            // alert(animationDuration);
          });

        document
          .getElementById("speed-x2")
          .addEventListener("click", function () {
            animationDuration = 5000;
            // alert(animationDuration);
          });

        document
          .getElementById("speed-x4")
          .addEventListener("click", function () {
            animationDuration = 1250;
            // alert(animationDuration);
          });

        function frame(time) {
          if (!start) start = time;
          // phase determines how far through the animation we are
          const phase = (time - start) / animationDuration;

          // phase is normalized between 0 and 1
          // when the animation is finished, reset start to loop the animation
          if (phase > 1) {
            // wait 1.5 seconds before looping
            setTimeout(() => {
              start = 0.0;
            }, 1500);
          }

          // use the phase to get a point that is the appropriate distance along the route
          // this approach syncs the camera and route positions ensuring they move
          // at roughly equal rates even if they don't contain the same number of points
          const alongRoute = turf.along(
            turf.lineString(targetRoute),
            routeDistance * phase
          ).geometry.coordinates;

          const alongCamera = turf.along(
            turf.lineString(cameraRoute),
            cameraRouteDistance * phase
          ).geometry.coordinates;

          const camera = map.getFreeCameraOptions();

          // set the position and altitude of the camera
          camera.position = mapboxgl.MercatorCoordinate.fromLngLat(
            {
              lng: alongCamera[0],
              lat: alongCamera[1],
            },
            cameraAltitude
          );

          // tell the camera to look at a point along the route
          camera.lookAtPoint({
            lng: alongRoute[0],
            lat: alongRoute[1],
          });

          map.setFreeCameraOptions(camera);

          window.requestAnimationFrame(frame);
        }

        window.requestAnimationFrame(frame);
      });
    }

    function reset() {
      mapLayer();
      modelBuildings();
      navControl();
      latestMarkerPopup();
    }

    function buttonListeners() {
      const replayHistoryBtn = document.getElementById("replayHistory");
      const speed1xBtn = document.getElementById("speed-x1");
      const speed2xBtn = document.getElementById("speed-x2");
      const speed4xBtn = document.getElementById("speed-x4");
      const latestPositionBtn = document.getElementById("latestPosition");
      const overviewBtn = document.getElementById("overview");

      speed1xBtn.classList.add("active");
      speed1xBtn.style.display = "none";
      speed2xBtn.style.display = "none";
      speed4xBtn.style.display = "none";
      overviewBtn.style.display = "none";

      replayHistoryBtn.addEventListener("click", function () {
        speed2xBtn.classList.remove("active");
        speed4xBtn.classList.remove("active");
        latestPositionBtn.classList.remove("active");
        overviewBtn.classList.remove("active");
        replayHistoryBtn.classList.add("active");
        speed1xBtn.classList.add("active");

        speed1xBtn.style.display = "inline-block";
        speed2xBtn.style.display = "inline-block";
        speed4xBtn.style.display = "inline-block";
        overviewBtn.style.display = "inline-block";
      });

      speed1xBtn.addEventListener("click", function () {
        speed2xBtn.classList.remove("active");
        speed4xBtn.classList.remove("active");
        latestPositionBtn.classList.remove("active");
        overviewBtn.classList.remove("active");
        speed1xBtn.classList.add("active");
      });

      speed2xBtn.addEventListener("click", function () {
        speed1xBtn.classList.remove("active");
        speed4xBtn.classList.remove("active");
        latestPositionBtn.classList.remove("active");
        overviewBtn.classList.remove("active");
        speed2xBtn.classList.add("active");
      });

      speed4xBtn.addEventListener("click", function () {
        speed1xBtn.classList.remove("active");
        speed2xBtn.classList.remove("active");
        latestPositionBtn.classList.remove("active");
        overviewBtn.classList.remove("active");
        speed4xBtn.classList.add("active");
      });

      latestPositionBtn.addEventListener("click", function () {
        replayHistoryBtn.classList.remove("active");
        speed1xBtn.classList.remove("active");
        speed2xBtn.classList.remove("active");
        speed4xBtn.classList.remove("active");
        latestPositionBtn.classList.add("active");

        speed1xBtn.style.display = "none";
        speed2xBtn.style.display = "none";
        speed4xBtn.style.display = "none";
        overviewBtn.style.display = "none";
      });

      overviewBtn.addEventListener("click", function () {
        speed2xBtn.classList.remove("active");
        speed4xBtn.classList.remove("active");
        latestPositionBtn.classList.remove("active");
        overviewBtn.classList.add("active");

        speed1xBtn.style.display = "none";
        speed2xBtn.style.display = "none";
        speed4xBtn.style.display = "none";
        // overviewBtn.style.display = "none";
      });
    }

    function overview() {
      let locations = []; // overview

      for (let i = 0; i < gon.device_locations.length - 1; i++) {
        locations.push([
          gon.device_locations[i].longitude,
          gon.device_locations[i].latitude,
        ]);
      }

      mapboxgl.accessToken =
        "pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
      // A GeoJSON object with a LineString route from the White House to Capitol Hill
      const geojson = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry: {
              type: "LineString",
              properties: {},
              coordinates: locations,
            },
          },
        ],
      };

      const map = new mapboxgl.Map({
        container: "test-map",
        // Choose from Mapbox's core styles, or make your own style with Mapbox Studio
        style: mapStyle,
        center: [
          gon.device_locations[0].longitude,
          gon.device_locations[0].latitude,
        ],
        zoom: 12,
        attributionControl: false,
      });

      map.on("load", () => {
        map.addSource("LineString", {
          type: "geojson",
          data: geojson,
        });
        map.addLayer({
          id: "LineString",
          type: "line",
          source: "LineString",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#000000",
            "line-width": 2,
          },
        });

        // Geographic coordinates of the LineString
        const coordinates = geojson.features[0].geometry.coordinates;

        // Create a 'LngLatBounds' with both corners at the first coordinate.
        const bounds = new mapboxgl.LngLatBounds(
          coordinates[0],
          coordinates[0]
        );

        // Extend the 'LngLatBounds' to include every coordinate in the bounds result.
        for (const coord of coordinates) {
          bounds.extend(coord);
        }

        map.fitBounds(bounds, {
          padding: 20,
        });
      });
    }

    function buttonsShow() {
      document.getElementById("replayHistory").style.visibility = "visible";
      document.getElementById("latestPosition").style.visibility = "visible";
    }

    function geofence() {
      mapboxgl.accessToken = "pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
      let map = new mapboxgl.Map({
        container: "test-map",
        style: "mapbox://styles/mapbox/streets-v11",
        center: [28.2710266, -26.1473083],
        zoom: 9,
      });

    }

    // Code logic:

    // Variables
    let map; // so that maplayers can be added within the functions
    const mapStyle = "mapbox://styles/mapbox/outdoors-v12";
    // const latlonlastCenter = [gon.device_locations[gon.device_locations - 1].longitude, gon.device_locations[gon.device_locations - 1].latitude];

    if (
      typeof gon.device_locations[0] === "undefined" ||
      gon.device_locations[0].latitude == ""
    ) {
      document.getElementById("test-map").remove();
    } else {
      buttonsShow();
      buttonListeners();
      mapLayer();
      modelBuildings();
      // navControl();
      latestMarkerPopup();
    }

    const replayBtn = document.getElementById("replayHistory");
    const latestBtn = document.getElementById("latestPosition");
    const overviewBtn = document.getElementById("overview");
    const geofenceBtn = document.getElementById("geofence");

    replayBtn.addEventListener("click", replayHistory);
    latestBtn.addEventListener("click", reset);
    overviewBtn.addEventListener("click", overview);
    geofenceBtn.addEventListener("click", geofence);

    // const speed2xBtn = document.getElementById("speed-x2");
    // const speed4xBtn = document.getElementById("speed-x4");
    // speed2xBtn.addEventListener("click", function(){
    //   animationDuration = 2500;
    // });
    // speed4xBtn.addEventListener("click", function(){
    //   animationDuration = 1250;
    // });
  } catch (error) {
    // handle error
    console.log(error);
    // alert("error called");
  }
});
