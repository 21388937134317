$(document).on('turbolinks:load', function(){

  if (location.hash) {
    $('a[href=\'' + location.hash + '\']').tab('show');
  }
  var activeTab = localStorage.getItem('activeTab');
  if (activeTab) {
    $('a[href="' + activeTab + '"]').tab('show');
  }

  $('body').on('click', 'a[data-toggle=\'tab\']', function (e) {
    e.preventDefault()
    var tab_name = this.getAttribute('href')
    if (history.pushState) {
      history.pushState(null, null, tab_name)
    }
    else {
      location.hash = tab_name
    }
    localStorage.setItem('activeTab', tab_name)

    $(this).tab('show');
    return false;
  });
  $(window).on('popstate', function () {
    var anchor = location.hash ||
      $('a[data-toggle=\'tab\']').first().attr('href');
    $('a[href=\'' + anchor + '\']').tab('show');
  });

  // for each tab link
  $('#devIndex-tabs a').each(function() {
    // does it's related div (by content id) not have a p element?
      if ($('#' + $(this).data('content-id')).has('p').length == 0) {
        // if not, find the link's parent li element and hide it
          $(this).closest('li').hide();
      }
  });
});

$(document).on('turbolinks:load', function(){
  $("#dgroup a.add_fields").
    data("association-insertion-position", 'before').
    data("association-insertion-node", 'this');

  $('#dgroup').bind('cocoon:after-insert',
     function() {
       $("#dgroup_from_list").hide();
       $("#dgroup a.add_fields").hide();
     });
  $('#dgroup').bind("cocoon:after-remove",
     function() {
       $("#dgroup_from_list").show();
       $("#dgroup a.add_fields").show();
     });
});


$(document).on('turbolinks:load', function(){
  function check_to_hide_or_show_add_link() {
    if ($('#dgroup .nested-fields:visible').length == 1) {
       $('#dgroup .links a').hide();
     } else {
       $('#dgroup .links a').show();
     }
   }

   $('#dgroup').on('cocoon:after-insert', function() {
     check_to_hide_or_show_add_link();
   });

   $('#dgroup').on('cocoon:after-remove', function() {
     check_to_hide_or_show_add_link();
   });
   check_to_hide_or_show_add_link();
});

$(document).on('turbolinks:load', function(){
  $("#dtype a.add_fields").
    data("association-insertion-position", 'before').
    data("association-insertion-node", 'this');

  $('#dtype').bind('cocoon:after-insert',
     function() {
       $("#dtype_from_list").hide();
       $("#dtype a.add_fields").hide();
     });
  $('#dtype').bind("cocoon:after-remove",
     function() {
       $("#dtype_from_list").show();
       $("#dtype a.add_fields").show();
     });
});


$(document).on('turbolinks:load', function(){
  function check_to_hide_or_show_add_link() {
    if ($('#dtype .nested-fields:visible').length == 1) {
       $('#dtype .links a').hide();
     } else {
       $('#dtype .links a').show();
     }
   }

   $('#dtype').on('cocoon:after-insert', function() {
     check_to_hide_or_show_add_link();
   });

   $('#dtype').on('cocoon:after-remove', function() {
     check_to_hide_or_show_add_link();
   });
   check_to_hide_or_show_add_link();
});

$(document).on('turbolinks:load', function(){
  $("#asetting a.add_fields").
    data("association-insertion-position", 'before').
    data("association-insertion-node", 'this');

  $('#asetting').bind('cocoon:after-insert',
     function() {
       $("#asetting_from_list").hide();
       $("#asetting a.add_fields").hide();
     });
  $('#asetting').bind("cocoon:after-remove",
     function() {
       $("#asetting_from_list").show();
       $("#asetting a.add_fields").show();
     });
});


$(document).on('turbolinks:load', function(){
  function check_to_hide_or_show_add_link() {
    if ($('#asetting .nested-fields:visible').length == 1) {
       $('#asetting .links a').hide();
     } else {
       $('#asetting .links a').show();
     }
   }

   $('#asetting').on('cocoon:after-insert', function() {
     check_to_hide_or_show_add_link();
   });

   $('#asetting').on('cocoon:after-remove', function() {
     check_to_hide_or_show_add_link();
   });
   check_to_hide_or_show_add_link();
});

$(document).on('turbolinks:load', function(){
  $("#stype a.add_fields").
    data("association-insertion-position", 'before').
    data("association-insertion-node", 'this');

  $('#stype').bind('cocoon:after-insert',
     function() {
       $("#stype_from_list").hide();
       $("#stype a.add_fields").hide();
     });
  $('#stype').bind("cocoon:after-remove",
     function() {
       $("#stype_from_list").show();
       $("#stype a.add_fields").show();
     });
});


$(document).on('turbolinks:load', function(){
  function check_to_hide_or_show_add_link() {
    if ($('#stype .nested-fields:visible').length == 1) {
       $('#stype .links a').hide();
     } else {
       $('#stype .links a').show();
     }
   }

   $('#stype').on('cocoon:after-insert', function() {
     check_to_hide_or_show_add_link();
   });

   $('#stype').on('cocoon:after-remove', function() {
     check_to_hide_or_show_add_link();
   });
   check_to_hide_or_show_add_link();
});
