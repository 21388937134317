import { formatLastSeen } from "../../functions.js";

$(document).on("turbolinks:load", function () {
  //console.log("Is turbolinks loading!")

  try {

    // Map is reusable, change gon &
    let map;
    let pulsingDot;

    function mapLayer() {
      // Create the map layer & style
      const mapBoxToken =
        "pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";

      mapboxgl.accessToken = mapBoxToken;
      map = new mapboxgl.Map({
        container: "summary-map-nss911", // container ID
        style: "mapbox://styles/mapbox/streets-v12", // style URL
        center: [
          gon.summary_location.longitude,
          gon.summary_location.latitude + 0.009,
        ], // starting position [lng, lat]
        attribution: "Nvirosense",
        zoom: 13, // starting zoom
        // pitch: 70,
        cooperativeGestures: true,
        attributionControl: false,
      });
      //End: Create the map layer & style
    }

    function pulsingDotInit() {
      // Pulsing Dot implement (Adding layer will be in > for loop> if statement)
      const size = 200;

      // This implements `StyleImageInterface`
      // to draw a pulsing dot icon on the map.
      const pulsingDot = {
        width: size,
        height: size,
        data: new Uint8Array(size * size * 4),

        // When the layer is added to the map,
        // get the rendering context for the map canvas.
        onAdd: function () {
          const canvas = document.createElement("canvas");
          canvas.width = this.width;
          canvas.height = this.height;
          this.context = canvas.getContext("2d");
        },

        // Call once before every frame where the icon will be used.
        render: function () {
          const duration = 1000;
          const t = (performance.now() % duration) / duration;

          const radius = (size / 2) * 0.3;
          const outerRadius = (size / 2) * 0.7 * t + radius;
          const context = this.context;

          // Draw the outer circle.
          context.clearRect(0, 0, this.width, this.height);
          context.beginPath();
          context.arc(
            this.width / 2,
            this.height / 2,
            outerRadius,
            0,
            Math.PI * 2
          );
          context.fillStyle = `rgba(255, 200, 200, ${1 - t})`;
          context.fill();

          // Draw the inner circle.
          context.beginPath();
          context.arc(this.width / 2, this.height / 2, radius, 0, Math.PI * 2);
          context.fillStyle = "rgba(255, 100, 100, 1)";
          context.strokeStyle = "white";
          context.lineWidth = 2 + 4 * (1 - t);
          context.fill();
          context.stroke();

          // Update this image's data with data from the canvas.
          this.data = context.getImageData(0, 0, this.width, this.height).data;

          // Continuously repaint the map, resulting
          // in the smooth animation of the dot.
          map.triggerRepaint();

          // Return `true` to let the map know that the image was updated.
          return true;
        },
      };
      // End: Pulsing Dot implement (Adding layer will be in > for loop> if statement)
      // Red pulse marker
      map.on("load", () => {
        map.addImage("pulsing-dot", pulsingDot, { pixelRatio: 1 });

        map.addSource("dot-point", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: [
              {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [
                    gon.device_location.longitude,
                    gon.device_location.latitude,
                  ], // icon position [lng, lat]
                },
              },
            ],
          },
        });
        map.addLayer({
          id: "layer-with-pulsing-dot",
          type: "symbol",
          source: "dot-point",
          layout: {
            "icon-image": "pulsing-dot",
          },
        });
      });
    }

    // for loop
    function coordMarkerPopup() {
      // Adds all the lats and lons from gon.jbuilder to the map, placing a marker and popup for each.

      const deviceName = gon.summary_location.device_name;
      // const deviceType = gon.summary_location[0].type;

      let deviceLat = gon.summary_location.latitude;
      let deviceLon = gon.summary_location.longitude;
      let fullCoords = deviceLat + "°<br>" + deviceLon + "°";

      let dateString = gon.summary_location.received_at;

      let date = new Date(dateString);
      let unixTime = date.getTime();

      const formatDate = gon.summary_location.received_at.slice(0, 10);
      const formatTime = gon.summary_location.received_at.slice(11, 19);

      let elapsedTime = formatLastSeen(unixTime);

      // API to GET adress --> "https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw"
      const mapboxApi =
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        deviceLon +
        "," +
        deviceLat +
        ".json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
      fetch(mapboxApi)
        .then((response) => response.json())
        .then((data) => {
          // Extract the place_name property and store it in a variable
          const placeName = data.features[0].place_name;

          // Popup
          let popup = new AnimatedPopup({
            offset: 25,
            openingAnimation: {
              duration: 600,
              easing: "easeOutElastic",
              transform: "scale",
            },
            closingAnimation: {
              duration: 200,
              easing: "easeInBack",
              transform: "scale",
            },
          }).setHTML(`
      <h1 class='popup-head'><strong>${deviceName}</strong></h1>
      <table class="summary-popup-nvirosense-nss911" style="table-layout: fixed; width: 199px">
      <colgroup>
      <col style="width: 90px">
      <col style="width: 80px">
      </colgroup>
      <thead>
        <tr>
          <th class="summary-popup-nvirosense-nss911-0a7q"><span style="font-weight:400;font-style:normal">Address:</span></th>
          <th class="summary-popup-nvirosense-nss911-73oq">${placeName}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="summary-popup-nvirosense-nss911-73oq"><span style="font-weight:400;font-style:normal">Time:</span></td>
          <td class="summary-popup-nvirosense-nss911-73oq">${formatDate}<br>${formatTime}</td>
        </tr>
        <tr>
          <td class="summary-popup-nvirosense-nss911-73oq"><span style="font-weight:400;font-style:normal">Last seen:</span></td>
          <td class="summary-popup-nvirosense-nss911-73oq">${elapsedTime}</td>
        </tr>
        <tr>
          <td class="summary-popup-nvirosense-nss911-73oq"><span style="font-weight:400;font-style:normal">Position:</span></td>
          <td class="summary-popup-nvirosense-nss911-73oq">${fullCoords}</td>
        </tr>
      </tbody>
      </table>
      `);
          // End: Popup

          // Marker
          const marker = new mapboxgl.Marker()
            .setLngLat([
              gon.summary_location.longitude,
              gon.summary_location.latitude,
            ])
            .setPopup(popup)
            .addTo(map);
          // End: Marker

          popup.addTo(map).setLngLat([longitude, latitude]).addTo(map);

          console.log(placeName);
        })
        .catch((error) => {
          // Handle any errors that occur during the fetch request
          // console.error(error);
        });
      // End: API to GET adress
    }

    function fullScreenControl() {
      map.addControl(new mapboxgl.FullscreenControl(), "bottom-right");
    }

    function lineBetweenMarkers() {
      // Adding a line from marker to marker (Feature to replaced/enhanced)
      map.on("load", () => {
        map.addSource("route", {
          type: "geojson",
          data: {
            type: "Feature",
            properties: {},
            geometry: {
              type: "LineString",
              coordinates: [],
            },
          },
        });

        const source = map.getSource("route");
        const data = source._data;

        source.setData(data);

        map.addLayer({
          id: "route",
          type: "line",
          source: "route",
          layout: {
            "line-join": "round",
            "line-cap": "round",
          },
          paint: {
            "line-color": "#888",
            "line-width": 8,
          },
        });
      });

      map.on("load", () => {
        map.setLayoutProperty("country-label", "text-field", [
          "format",
          ["get", "name_en"],
          { "font-scale": 1.2 },
          "\n",
          {},
          ["get", "name"],
          {
            "font-scale": 0.8,
            "text-font": [
              "literal",
              ["DIN Offc Pro Italic", "Arial Unicode MS Regular"],
            ],
          },
        ]);
      });
    }

    function modelBuildings() {
      map.on("style.load", () => {
        // Insert the layer beneath any symbol layer.
        const layers = map.getStyle().layers;
        const labelLayerId = layers.find(
          (layer) => layer.type === "symbol" && layer.layout["text-field"]
        ).id;

        // The 'building' layer in the Mapbox Streets
        // vector tileset contains building height data
        // from OpenStreetMap.
        map.addLayer(
          {
            id: "add-3d-buildings",
            source: "composite",
            "source-layer": "building",
            filter: ["==", "extrude", "true"],
            type: "fill-extrusion",
            minzoom: 15,
            paint: {
              "fill-extrusion-color": "#aaa",

              // Use an 'interpolate' expression to
              // add a smooth transition effect to
              // the buildings as the user zooms in.
              "fill-extrusion-height": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "height"],
              ],
              "fill-extrusion-base": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "min_height"],
              ],
              "fill-extrusion-opacity": 0.6,
            },
          },
          labelLayerId
        );
      });
    }

    function navControl() {
      // Adds zoom and navigation controls
      map.addControl(new mapboxgl.NavigationControl());
    }


    if (typeof gon.summary_location === "undefined") {
      document.getElementById("summary-map-nss911").remove();
    } else {
      mapLayer();
      coordMarkerPopup();
      modelBuildings();
      navControl();
    }


  } catch (error) {
    // handle error
    console.log(error);
    // alert("error called");
  }
});




