import { formatLastSeen } from "../../functions.js";

$(document).on("turbolinks:load", function () {
  //console.log("Is turbolinks loading!")

  try {
    let map;
    let pulsingDot;
    const summaryLocation = gon.wind_graphs[0].latlon;
    const [latitude, longitude] = summaryLocation.split(",").map(parseFloat);

    function mapLayer() {
      // Create the map layer & style
      const mapBoxToken =
        "pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";

      mapboxgl.accessToken = mapBoxToken;
      map = new mapboxgl.Map({
        container: "summary-map-nss710", // container ID
        style: "mapbox://styles/mapbox/streets-v12", // style URL
        center: [longitude, latitude + 0.009], // starting position [lng, lat]
        attribution: "Nvirosense",
        zoom: 13, // starting zoom
        // pitch: 70,
        cooperativeGestures: true,
        attributionControl: false,
      });
      //End: Create the map layer & style
    }

    function coordMarkerPopup() {
      // Adds all the lats and lons from gon.jbuilder to the map, placing a marker and popup for each.

      const deviceName = gon.wind_graphs[0].device_name;
      // const deviceType = gon.summary_location[0].type;

      let fullCoords = latitude + "°<br>" + longitude + "°";

      let dateString = gon.wind_graphs[0].received_at;

      let date = new Date(dateString);
      let unixTime = date.getTime();

      const formatDate = gon.wind_graphs[0].received_at.slice(0, 10);
      const formatTime = gon.wind_graphs[0].received_at.slice(11, 19);

      let elapsedTime = formatLastSeen(unixTime);

      // API to GET adress --> "https://api.mapbox.com/geocoding/v5/{endpoint}/{longitude},{latitude}.json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw"
      const mapboxApi =
        "https://api.mapbox.com/geocoding/v5/mapbox.places/" +
        longitude +
        "," +
        latitude +
        ".json?access_token=pk.eyJ1IjoiZXVjYS1udmlyb3NlbnNlIiwiYSI6ImNsZXBnMTB2eDA4YWMzem1qZWZteWR2dGMifQ.CNmGy33tANtwoEjaww9PNw";
      fetch(mapboxApi)
        .then((response) => response.json())
        .then((data) => {
          // Extract the place_name property and store it in a variable
          const placeName = data.features[0].place_name;

          // Popup
          let popup = new AnimatedPopup({
            offset: 25,
            openingAnimation: {
              duration: 600,
              easing: "easeOutElastic",
              transform: "scale",
            },
            closingAnimation: {
              duration: 200,
              easing: "easeInBack",
              transform: "scale",
            },
          }).setHTML(`
      <h1 class='popup-head'><strong>${deviceName}</strong></h1>
      <table class="summary-popup-nvirosense" style="table-layout: fixed; width: 199px">
      <colgroup>
      <col style="width: 90px">
      <col style="width: 80px">
      </colgroup>
      <thead>
        <tr>
          <th class="summary-popup-nvirosense-0a7q"><span style="font-weight:400;font-style:normal">Address:</span></th>
          <th class="summary-popup-nvirosense-73oq">${placeName}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td class="summary-popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal">Last Uplink:</span></td>
          <td class="summary-popup-nvirosense-73oq">${formatDate}<br>${formatTime}</td>
        </tr>
        <tr>
          <td class="summary-popup-nvirosense-73oq"><span style="font-weight:400;font-style:normal">Position:</span></td>
          <td class="summary-popup-nvirosense-73oq">${fullCoords}</td>
        </tr>
      </tbody>
      </table>
      `);
          // End: Popup

          // Marker
          const marker = new mapboxgl.Marker()
            .setLngLat([longitude, latitude])
            .setPopup(popup)
            .addTo(map);
          // End: Marker

          popup.addTo(map).setLngLat([longitude, latitude]).addTo(map);

          console.log(placeName);
        })
        .catch((error) => {
          // Handle any errors that occur during the fetch request
          // console.error(error);
        });
      // End: API to GET adress
    }

    function modelBuildings() {
      map.on("style.load", () => {
        // Insert the layer beneath any symbol layer.
        const layers = map.getStyle().layers;
        const labelLayerId = layers.find(
          (layer) => layer.type === "symbol" && layer.layout["text-field"]
        ).id;

        // The 'building' layer in the Mapbox Streets
        // vector tileset contains building height data
        // from OpenStreetMap.
        map.addLayer(
          {
            id: "add-3d-buildings",
            source: "composite",
            "source-layer": "building",
            filter: ["==", "extrude", "true"],
            type: "fill-extrusion",
            minzoom: 15,
            paint: {
              "fill-extrusion-color": "#aaa",

              // Use an 'interpolate' expression to
              // add a smooth transition effect to
              // the buildings as the user zooms in.
              "fill-extrusion-height": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "height"],
              ],
              "fill-extrusion-base": [
                "interpolate",
                ["linear"],
                ["zoom"],
                15,
                0,
                15.05,
                ["get", "min_height"],
              ],
              "fill-extrusion-opacity": 0.6,
            },
          },
          labelLayerId
        );
      });
    }

    function navControl() {
      // Adds zoom and navigation controls
      map.addControl(new mapboxgl.NavigationControl());
    }

    if (typeof gon.wind_graphs === "undefined" || gon.wind_graphs[0].latlon === "") {
      document.getElementById("summary-map-nss710").remove();
    } else {
      mapLayer();
      coordMarkerPopup();
      modelBuildings();
      navControl();
    }
  } catch (error) {
    // handle error
    console.log(error);
    // alert("error called");
  }
});
